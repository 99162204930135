import React from 'react';
import styled from 'styled-components';

const LogoSvg = styled.div`
  margin: auto;
`;

const LogoText = styled.div`
  color: #000;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 1.6em;
  margin-left: 15px;
  padding-bottom: .2em;
  vertical-align: middle;
  text-transform: uppercase;

  @media (max-width: 450px) {
    vertical-align: middle;
    font-size: 1.4em;
    margin-left: 5px;
    padding-bottom: 0;
  }
`;

const LogoContainer = styled.div`
  display: flex;
`;

export default function Logo({ fill }) {
  return (
    <LogoContainer>
      <LogoSvg>
        <svg preserveAspectRatio="xMidYMid meet"
          width="50px"
          height="40px"
          viewBox="0 0 1019 778">
          <g 
            transform="translate(0,778) scale(0.1,-0.1)"
            fill="#000" 
            stroke="none">
          <path d="M4868 7719 c-773 -53 -1462 -525 -1797 -1229 -89 -188 -154 -405 -187 -625 -22 -148 -22 -451 0 -600 72 -478 275 -885 611 -1220 332 -333 701 -524 1180 -611 137 -25 469 -30 623 -10 549 72 1034 337 1383 754 330 396 500
              866 500 1386 0 494 -144 923 -444 1321 -75 99 -297 321 -397 397 -426 321 -940 474 -1472 437z m-288 -1015 c142 -42 291 -155 391 -297 26 -37 50 -68 54 -68 4 0 28 31 54 68 182 259 447 369 730 302 70 -17 199 -83 263 -134 106 -85
              196 -228 240 -382 20 -66 23 -101 22 -228 0 -141 -2 -156 -32 -245 -90 -272 -280 -487 -896 -1015 -306 -263 -353 -299 -382 -299 -20 0 -43 8 -56 21 -13 11 -167 143 -343 293 -359 307 -626 567 -724 705 -72 102 -141 239 -168 335
              -14 50 -18 100 -17 220 0 142 2 162 28 240 69 211 197 361 379 445 39 18 97 38 127 44 30 6 64 13 75 15 38 9 200 -3 255 -20z" />
          <path d="M9620 6835 c-573 -100 -1327 -633 -1819 -1285 -384 -511 -599 -1007 -693 -1603 -20 -125 -23 -181 -23 -462 l0 -321 -65 -92 c-36 -50 -109 -156 -163 -234 -54 -79 -159 -231 -233 -338 -74 -107 -165 -238 -200 -290 -36 -52 -112 -162 -169 -245 -57 -82 -129 -186 -159 -230 -30 -44 -100 -145 -155 -225
            -55 -80 -139 -201 -186 -270 -48 -69 -124 -179 -170 -245 -131 -186 -164 -246 -187 -338 -18 -70 -19 -91 -10 -144 5 -34 19 -75 31 -91 11 -17 114 -87 238 -165 285 -177 293 -181 351 -175 131 13 229 104 382 356 58 95 135 221 172 280 36 59 95 153 130 209 126 205 377 610 441 713 36 58 94 150 127 205 34 55
            91 148 128 207 37 59 157 253 266 430 110 178 218 352 241 388 23 36 61 97 84 135 23 39 59 97 80 130 39 62 285 459 445 718 147 238 212 343 380 615 88 141 211 340 274 442 222 358 245 395 277 445 17 28 98 158 180 290 278 451 357 580 390 630 87 134 125 235 124 335 0 101 -31 155 -109 195 -91 46 -244 57 -400 30z" />
          <path d="M1335 6668 c-104 -37 -163 -141 -144 -257 9 -56 16 -69 204 -376 54 -88 154 -252 223 -365 69 -113 181 -297 250 -410 313 -514 363 -600 360 -616 -4 -21 -44 -51 -80 -59 -26 -7 -39 12 -444 622 -229 345 -477 718 -550 829 -77 117 -150 216 -174 237 -85 72 -211 72 -292 -1 -57 -52 -78 -99 -78 -176 0
            -75 -6 -63 242 -506 91 -162 189 -338 218 -390 29 -52 104 -185 165 -295 62 -110 127 -227 145 -260 18 -33 73 -131 122 -218 60 -107 87 -164 83 -177 -9 -28 -71 -63 -98 -56 -14 4 -40 36 -73 94 -28 48 -81 137 -119 197 -37 61 -93 153 -125 205 -31 52 -115 190 -185 305 -71 116 -185 302 -254 415 -68 113
            -160 264 -204 335 -90 149 -114 177 -175 206 -166 81 -356 -64 -321 -245 18 -95 536 -1169 751 -1555 133 -238 270 -424 439 -592 200 -198 417 -331 638 -390 76 -20 250 -40 280 -32 15 4 177 -279 724 -1261 16 -28 52 -91 79 -141 28 -49 62 -110 76 -135 14 -25 42 -74 62 -110 20 -36 49 -86 63 -112 15 -26
            46 -83 70 -125 51 -90 72 -129 175 -313 41 -74 94 -169 117 -210 53 -92 81 -142 192 -344 134 -242 228 -328 364 -329 61 0 31 -17 468 248 179 109 204 133 220 222 13 65 0 146 -34 220 -13 30 -80 139 -148 242 -68 102 -230 346 -359 541 -129 195 -319 483 -423 640 -104 157 -325 492 -493 745 -167 253
            -332 502 -366 554 l-63 93 30 62 c71 147 98 276 98 480 -1 76 -3 155 -7 175 -51 315 -146 569 -319 856 -59 97 -98 155 -260 385 -158 225 -759 1020 -823 1088 -59 63 -165 89 -247 60z" />
          </g>
        </svg>
      </LogoSvg>
      <LogoText>Serving For Two</LogoText>
    </LogoContainer>
  );
}